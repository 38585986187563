import { useEffect } from 'react'
import { Redirect, useLocation, useHistory } from 'react-router'
import { Heading, Text } from '@bp-digital/component-typography'
import { Button } from '@bp-digital/component-button'
import { useRootStore } from 'contexts/StoreContext'
import styled from 'styled-components'
import useContent from 'hooks/useContent'
import PageHeader from 'components/layout/PageHeader'
import InnerPageWrapper from 'components/layout/InnerPageWrapper'
import TwoColumn from 'components/layout/TwoColumn'
import CardPreview from 'components/cards/CardPreview'
import headerImage from 'src/assets/headers/header-confirmation.jpg'
import { getCardImage, getCardImageProperties } from 'components/cards/helpers'
import { ROUTE_CARDS_ORDER, ROUTE_CARDS_VIEW } from 'constants/routes'
import { ButtonWrapper, Spacer } from 'styles/common.styled'

const CardWrapper = styled.div`
  margin: auto;
`

const CardOrderConfirmationPage = () => {
  const { viewManageCardStore, cardReissueStore, userStore } = useRootStore()
  const content = useContent('cards-order')
  const replacementCardContent = useContent('cards-view')
  const history = useHistory()
  let { state: { referrer, deliveryType, countryCode, authorityDetails, isReplacementCardEnabled } = {} } =
    useLocation()
  const cardImage = getCardImage(getCardImageProperties(authorityDetails))

  useEffect(() => {
    // Clear previous card data
    if (userStore?.selectedAuthorityId) {
      viewManageCardStore?.reset()
      cardReissueStore?.reset()
    }
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  return referrer === ROUTE_CARDS_ORDER ? (
    <>
      <PageHeader
        title={
          isReplacementCardEnabled
            ? replacementCardContent?.order_replacement_card_modal_title
            : content?.confirmation_title
        }
        breadcrumbs={[
          { to: null, title: 'Card Management', ariaLabel: 'Card Management' },
          { to: null, title: 'Card Order', ariaLabel: 'Card order' }
        ]}
        headerImage={headerImage ?? ''}
        brand={userStore?.brand}
      />
      <InnerPageWrapper>
        <Heading as='h2' data-content-key='confirmation_heading'>
          {content?.confirmation_heading}
        </Heading>

        <TwoColumn>
          <div>
            <Text as='p' data-content-key='confirmation_message'>
              {content?.confirmation_message}
            </Text>

            <Text data-content-key='confirmation_go_to_alerts'>
              {content?.confirmation_go_to_alerts}

              {/* Notifications to be added later */}
              {/* <Link path='' data-content-key="confirmation_go_to_alerts_link"> */}
              {/* {content?.confirmation_go_to_alerts_link} */}
              {/* </Link> */}
            </Text>

            <Spacer size='xl' />

            <Heading as='h2' size='h5' data-content-key='confirmation_next_heading'>
              {content?.confirmation_next_heading}
            </Heading>

            <ButtonWrapper gap='xl'>
              <Button
                onClick={() => history.replace(ROUTE_CARDS_VIEW, {})}
                dataAttributes={{ 'data-content-key': 'confirmation_manage_button' }}
              >
                {content?.confirmation_manage_button}
              </Button>

              <Button
                onClick={() => history.replace(ROUTE_CARDS_ORDER, {})}
                dataAttributes={{ 'data-content-key': 'confirmation_order_button' }}
              >
                {content?.confirmation_order_button}
              </Button>
            </ButtonWrapper>
          </div>

          <CardWrapper>
            <CardPreview
              image={cardImage?.default}
              cardNumber={String(deliveryType?.serial)}
              embossingLines={[
                deliveryType?.embossingLine1,
                deliveryType?.embossingLine2,
                deliveryType?.embossingLine3
              ]}
              countryCode={countryCode ?? ''}
              expiryMonth={deliveryType?.expiryMonth}
              expiryYear={deliveryType?.expiryYear}
            />
          </CardWrapper>
        </TwoColumn>
      </InnerPageWrapper>
    </>
  ) : (
    <Redirect
      to={{
        pathname: ROUTE_CARDS_ORDER,
        state: { referrer: undefined }
      }}
    />
  )
}

export default CardOrderConfirmationPage
